const forms = document.querySelectorAll('form');

let flagCaptcha = false;

const errorLabelObj = {
    color: '#e90707',
    marginTop: '6px',
    fontSize: '14px',
    textAlign: 'left',
}


forms.forEach((form, i) => {
    form.insertAdjacentHTML('beforeend', `<div id="recaptcha-${i + 1}"></div>`);
})

// нужен именно var
var onloadCallback = function () {
    const KEY = '6Lc0F6whAAAAAI0yHeOIz3nr56TDrFZlHvkvN4sU';
    forms.forEach((form, i) => {
        grecaptcha.render(`recaptcha-${i + 1}`, {
            'sitekey': KEY,
            'callback': captchaCheck,
        })
    })
}

const captchaCheck = () => {
    flagCaptcha = true;
}

const sendForm = (event) => {

/*     const fileInput = document.querySelector('input[type="file"]');
    fileInput.addEventListener('change', (e) => {
        let files = e.currentTarget.files;
        console.log(files);

        if (files.length) {
            fileInput.closest('label').querySelector('span').textContent = files[0].name;
        } else {
            fileInput.closest('label').querySelector('span').textContent = 'Прикрепить файл';
        }

    }); */




    const formData = new FormData(event);
    fetch('mail/mail.php', {
        method: 'POST',
        body: formData
    })
        .then((data) => {
            if (data.status === 200) {
                window.location = "/thanks";
       /*          $('.fade').delay(1700).fadeOut(300);
                $('#success').fadeIn(500).delay(1700).fadeOut(600); */
            } else {
                console.log('Ошибка');
                //openModal(modalError);
            }
        })
        .catch(error => {
            console.log('Ошибка');
        });
}

const formHandler = (event) => {
    sendForm(event.target);
    event.target.reset();
}

// Валидация

if (document.querySelector('#callme-modal')) {
    const formQstValid = new JustValidate('#callme-modal', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#callme-modal [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])


        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}



if (document.querySelector('#page-form')) {
    const formQstValid = new JustValidate('#page-form', {
        errorFieldCssClass: 'invalid',
        errorLabelStyle: errorLabelObj,
    });
    formQstValid

        .addField('#page-form [type="tel"]', [{
            rule: 'required',
            value: true,
            errorMessage: 'Поле обязательно для заполнения',
        },
        {
            rule: 'minLength',
            value: 16,
            errorMessage: 'Введите телефон в формате +7(***) *** ** **'
        },
        ])


        .onSuccess((event) => {
            if (flagCaptcha) {
                formHandler(event);
                flagCaptcha = false;
            }
        });
}





const maskedPhoneNumbers = () => {
    let phoneNumber = document.querySelectorAll('[type="tel"]');

    if (phoneNumber) {
        phoneNumber.forEach(function (el) {
            el.setAttribute('data-mask', '+7 000 000 00 00');
            el.addEventListener('input', function (event) {
                showMask(event);
            });
        });
    }

    function showMask(event) {
        const digitalPattern = /[0-9]/;
        const literalPattern = /[0\*]/;
        let input = event.target;
        let value = input.value;
        let mask = input.dataset.mask;
        let newValue = '';

        try {
            let maskLength = mask.length;
            let valueIndex = 0;
            let maskIndex = 0;

            for (; maskIndex < maskLength;) {
                if (maskIndex >= value.length) {
                    break;
                }

                if (mask[maskIndex] === '0' && value[valueIndex].match(digitalPattern) === null) {
                    break;
                }

                while (mask[maskIndex].match(literalPattern) === null) {
                    if (value[valueIndex] === mask[maskIndex]) {
                        break;
                    }

                    newValue += mask[maskIndex++];
                } //end cycle while

                newValue += value[valueIndex++];
                maskIndex++;
            } //end cycle for

            input.value = newValue;
        } catch (evt) {
            phoneNumber.removeEventListener();
        }
    }
}
maskedPhoneNumbers();
